import React, {FC, useEffect} from 'react';
import {IProjectGridProps} from '@common/types';
import {getCardClassNames} from '@common/functions';
import {EComponent, ITag} from '@redux/types';
import {useFilterBySelectTag} from '@common/hooks/useFilterBySelectTag';
import {useSelectTag} from '@common/hooks/useSelectTag';
import Picture from '@components/component/Picture';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {IProject} from '@redux/projects/types';
import {settings} from '@common/settings';
import Text from '@components/Text';
import Link from 'next/link';


const ProjectGrid: FC<IProjectGridProps> = ({ projects, isHomePageGrid}) => {
  const router = useRouter()
  const {selectedTagSlug, setSelectedTagSlug} = useSelectTag('projects')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash;
      setSelectedTagSlug(hash.split('#')[1])
    }
  }, [])

  const {wasTagChanged, items} = useFilterBySelectTag({
    selectedTagSlug: isHomePageGrid ? null : selectedTagSlug ?? null,
    initialData: projects || [],
    manyTags: true
  })

  const redirectToProject = (slug: string) => {
    router.push(`/projects#${slug}`)
  }

  return (
    <ul className={`project__list ${wasTagChanged ? 'is-active' : ''} ${selectedTagSlug ? 'is-filtered' : ''}`}>
      {items?.map((p: IProject) => {
        if (!p.image && !p.icon) {
          return null
        }
        const classNames = [
          'project__item', `project__item--bg-${p?.color ?? ''}`,
          ...getCardClassNames(p.cardType || null, 'project__item')
        ]

        return (
          <li className={classNames.join(' ')} key={p.slug}>
            <article className="project__item-wrapper">
              {!!p.image && (
                <>
                  <div className="project__picture project__shadow-picture">
                    <Picture isProjectPreview={true} withoutWrapper={true} media={{
                      __component: EComponent.MEDIA,
                      image: p?.changeCardTypeForPreview ? p?.imageMobile || p.image : p?.image,
                      imageMobile: p?.imageMobile}}
                    />
                  </div>
                  <div className="project__picture project__real-picture">
                    <Picture isProjectPreview={true} withoutWrapper={true} media={{
                      __component: EComponent.MEDIA,
                      image: p?.changeCardTypeForPreview ? p?.imageMobile || p.image : p?.image,
                      imageMobile: p?.imageMobile}}
                    />
                  </div>
                </>
              )}

              {!!p?.icon?.url && (
                <Image className="project__icon" width="120" height="120"
                       alt={"icon"}
                       src={`${p?.icon?.url || ''}`}/>
              )}

              <h3 className="project__title"><Text text={p.title}/></h3>
              <div className="project__tags">
                {p?.tags?.map((t: ITag) => (
                  <a key={t.slug} className={`project__tag`} href={`#${t?.slug}`}
                    onClick={(e) => isHomePageGrid ? redirectToProject(t.slug) : setSelectedTagSlug(t.slug, e)}
                  >
                    {t?.name}
                  </a>
                ))}
              </div>
              {!p?.meta?.onlyPreview && (
                <Link
                  className="project__shadow-link"
                  aria-label="Переход к проекту"
                  locale={router.locale}
                  href={`/projects/${p.slug}`}
                />
              )}
            </article>
          </li>
        )
      })}
    </ul>
  )
}

export default ProjectGrid
