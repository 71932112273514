import React, {FC, memo} from 'react';
import {IPictureProps} from '@common/types';
import {settings} from '@common/settings';
import Image from 'next/image';
import Text from '@components/Text';


const Picture: FC<IPictureProps> = ({media, wrapperClassName,
                                      isProjectPreview= false,
                                      wrapperCaptionClassName,
                                      withoutWrapper}) => {

  const _getImageUrl = (url?: string | undefined) => {
    return !url ? '' : url
  }

  const PictureBlock = () => {
    if (!media || (!media.image && !media?.url)) {
      return ''
    }

    const file = media.image

    if (media?.url && !file?.url) {
      return (
        <Image src={media?.url} alt={file?.alternativeText || ''} width={500} height={500}/>
      )
    }

    const src = _getImageUrl(file?.url)
    const mobileSrc = _getImageUrl(media?.imageMobile?.url)

    const defaultSrc = src
    const xLargeUrl = _getImageUrl(file?.formats?.xlarge?.url) || defaultSrc
    const largeUrl = _getImageUrl(file?.formats?.large?.url) || xLargeUrl
    const mediumUrl = _getImageUrl(file?.formats?.medium?.url) || largeUrl
    const smallUrl = _getImageUrl(file?.formats?.small?.url) || mediumUrl

    const xLargeUrl_x2 = _getImageUrl(file?.formats?.xlarge_x2?.url) || xLargeUrl
    const largeUrl_x2 = _getImageUrl(file?.formats?.large_x2?.url) || xLargeUrl_x2
    const mediumUrl_x2 = _getImageUrl(file?.formats?.medium_x2?.url) || largeUrl_x2
    const smallUrl_x2 = _getImageUrl(file?.formats?.small_x2?.url) || mediumUrl_x2

    const defaultMobileSrc = _getImageUrl(media?.imageMobile?.formats?.medium?.url) || mobileSrc
    const mobileSmallUrl = _getImageUrl(media?.imageMobile?.formats?.small?.url) || defaultMobileSrc
    const defaultMobileSrc_x2 = _getImageUrl(media?.imageMobile?.formats?.medium_x2?.url) || defaultMobileSrc
    const mobileSmallUrl_x2 = _getImageUrl(media?.imageMobile?.formats?.small_x2?.url) || defaultMobileSrc_x2

    return (
      <picture>
        {media?.imageMobile ? (
          <>
            <source type={media.imageMobile.mime} media="(max-width: 575px)"
                    srcSet={`${mobileSmallUrl} 1x, ${mobileSmallUrl_x2} 2x`}/>
            <source type={media.imageMobile.mime} media="(max-width: 767px)"
                    srcSet={`${defaultMobileSrc} 1x, ${defaultMobileSrc_x2} 2x`}/>
            {isProjectPreview && (
              <source type={media.imageMobile.mime} media="(max-width: 1023px)"
                      srcSet={`${defaultMobileSrc} 1x, ${defaultMobileSrc_x2} 2x`}/>
            )}
          </>
        ) : (
          <>
            {smallUrl && (
              <source type={file?.mime} media="(max-width: 575px)"
                      srcSet={`${smallUrl} 1x, ${smallUrl_x2} 2x`}/>
            )}
            {mediumUrl && (
              <source type={file?.mime} media="(max-width: 767px)"
                      srcSet={`${mediumUrl} 1x, ${mediumUrl_x2} 2x`}/>
            )}
          </>
        )}
        <source media="(max-width: 1280px)" type={file?.mime} srcSet={`${largeUrl} 1x, ${largeUrl_x2} 2x`}/>
        <source media="(max-width: 1920px)" type={file?.mime} srcSet={`${xLargeUrl} 1x, ${defaultSrc} 2x`}/>
        <Image
          src={defaultSrc}
          width={file?.width}
          height={file?.height}
          alt={file?.alternativeText || ''}
        />
      </picture>
    )
  }

  return (
    <>
      {withoutWrapper ? (
        <PictureBlock/>
      ) : (
        <div className={`inner-picture ${wrapperClassName ?? ''}`}>
          <PictureBlock/>
        </div>
      )}
      {media?.caption && (
        <p className={`inner-grid__picture-text ${wrapperCaptionClassName ?? ''}`}><Text text={media.caption}/></p>
      )}
    </>
  )
}

export default memo(Picture)
